import React from 'react';
//import { useState } from 'react';
import './about.component.css';

function About() {

  return (
    <div className="About">
      <main>
        <div className="about-us-section">
          <h2 className='about-h2'>About Us</h2>
          <p className='about-p'>Welcome! We are a small team of passionate engineers spread across the globe and we firmly believe that technology should be accessible to everyone, no matter their background or formal education in computer science. Our mission? Empowering individuals, especially those without traditional training, to unleash their creativity and pursue their passions.
          Diving into the coding world can be daunting, but fear not! We're here to simplify the process and make it enjoyable for you. With our cutting-edge platform, you'll conquer the challenges without the steep learning curve. Unleash your imagination, bring your ideas to life, and build something you'll be proud of.
          Join us as we revolutionize the way people create web applications, opening the doors to a more inclusive and accessible digital realm. We're not just a team; we're a vibrant community that thrives on collaboration and innovation.
          If you want to learn more about our platform, other projects, or want to become part of our dynamic team, don't hesitate to reach out to us directly at devflowpro@gmail.com. We're eagerly waiting to hear from you and embark on this incredible journey together!</p>
          <div className='features-section'>
          <p>Please note that DevFlow is currently under development. We're working hard to bring you a top-notch platform for web application creation. Stay tuned for regular updates, new features, and exciting developments as we shape the future of this remarkable tool. Your support and patience are greatly appreciated as we strive to deliver the best possible experience. Thank you for joining us on this journey!</p>
            <div className='about-features'>
              <div>
                <h2 className='about-h2'>Features</h2>
                <ul className='about-ul'>
                  <li className='about-li'>Easy-to-use natural language processing (NLP) interface</li>
                  <li className='about-li'>Code generation</li>
                  <li className='about-li'>Live preview and link to generated website</li>
                  <li className='about-li'>Interactive development tools</li>
                </ul>
              </div>
              <div>
                <h2 className='about-h2'>To come...</h2>
                <ul className='about-ul'>
                  <li className='about-li'>Automatic image/media generation</li>
                  <li className='about-li'>Integrated hosting service</li>
                </ul>  
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default About;