import { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../contexts/user.content";
import "./home.component.css";

const Home = () => {
    const { currentUser, setCurrentUser } = useContext(UserContext)

    //console.log(currentUser)
    const rt = currentUser ? '/auth' : '/quick_start'
    //console.log(rt)

    return(
    <div className="hero-section">
        <div>
            <h1>Welcome to DevFlow!</h1>
            <p>DevFlow is a revolutionary web development tool that harnesses the power of natural language processing (NLP) to enable users to create high-quality websites and web applications with ease. With DevFlow, all you need to do is tell the app what you want your website to look like and what features you want it to have using simple, human-readable language. The app will take care of the rest, generating the necessary code and resources automatically. What's more, DevFlowPro is designed to replace large web development teams by providing a more efficient and cost-effective way to build websites and web applications. Whether you're a solo developer or part of a small team, DevFlowPro is the perfect tool to take your web development projects to the next level.</p>
        </div>
        <div className="home-buttons-container">
            <div>
                <Link to={currentUser ? '/quick_start' : '/auth'} className='cta-button'>Get Started</Link>
            </div>
            <div>
                <Link to='/payment' className='payment-button'>Pay ME</Link>
            </div>
        </div>
    </div>
    );
};

export default Home;