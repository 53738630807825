import "./contact.component.css";

const Contact = () => {
    return (
        <div class="contact-container">
            <h2>Contact Us</h2>
            <p>We love to hear from our users! Feel to contact our team about anything by shooting an email here:</p>
            <div class="contact-item">
                <span>devflowpro@gmail.com</span>
            </div>
            <p>... or sending a text or calling this number:</p>
            <div class="contact-item">
                <span>+1 (630) 251-9140</span>
            </div>
        </div>
    );
}

export default Contact;
