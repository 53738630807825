import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Input from "../../components/input_field/input_field.component";

const QuickStart = () => {
    const [response, setResponse] = useState(null);
    const [description, setDescription] = useState('');
    const [name, setName] = useState('');
    const [waiting, setWaiting] = useState(false);
    const navigate = useNavigate();

    const onNameChange = (event) => {
        setName(event.target.value)
    }
    const onDescriptionChange = (event) => {
        setDescription(event.target.value)
    }

    const handleButtonClick = (event) => {
        console.log(description);
        setWaiting(true);
        setResponse(null);

        const url = new URL('http://devflowpro.com/prompt/');
        url.searchParams.append('name', name)
        url.searchParams.append('description', description);
    
        console.log(url)
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            setResponse(data);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setWaiting(false);
          });

        // console.log(name)
        // navigate(name + "")
    };


    return(
    <main>
        <div className="tools-container">
            <h2>Tools</h2>
            <ul>
                <Input className="input-site-details" value={name} onChangeHandler={onNameChange} placeholder="name your site" />
                <Input className="input-site-details" value={description} onChangeHandler={onDescriptionChange} placeholder="describe you site here" /> 
                <button className="generate-site-button" onClick={handleButtonClick}> { waiting ? "Loading..." : "Generate site" }</button>
                {/* <li><a href="#">Customization Tool 1</a></li>
                <li><a href="#">Customization Tool 2</a></li>
                <li><a href="#">Customization Tool 3</a></li> */}
            </ul>
        </div>


        <div className="response-container">
        {response && (
          <div>
            <h1>URL: {response.url}</h1>
            <h1>STDOUT: {response.STDOUT}</h1>
            <h1>STDERR: {response.STDERR}</h1>
          </div>
        )}
      </div>

        <div className="preview-container">
            <iframe title="Customized Site" src={"http://devflowpro.com/" + name + ".html?rand=" + Math.random()}></iframe> 
        </div>
    </main>
    )
}

export default QuickStart;

