import { useState, useContext } from "react";
import { createAuthUserWithEmailAndPassword, createUserDocumentFromAuth } from "../../utils/firebase/firebase.utils";
import FormInput from "../form-input/form-input.component";
import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";
import "./sign-in-form.styles.css";
import { signInWithGooglePopup, signInAuthUserWithEmailAndPassword } from "../../utils/firebase/firebase.utils";
import { UserContext } from "../../contexts/user.content";
import { useNavigate } from 'react-router-dom';

const defaultFormFields = {
    email: '',
    password: '',
};

const SignInForm = () => {
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { displayName, email, password, confirmPassword } = formFields;

    const {setCurrentUser } = useContext(UserContext);
    const nav = useNavigate();

    //console.log(formFields);
    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }

    const signInWithGoogle = async () => {
        await signInWithGooglePopup();
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const user = await signInAuthUserWithEmailAndPassword(email, password);
            console.log(user);
            resetFormFields();
            nav('/quick_start');

        } catch(error) {
            //console.log(error);
            switch(error.code) {
                case 'auth/wrong-password':
                    alert("wrong password");
                    break;
                case 'auth/user-not-found':
                    alert('wrong email');
                    break;
                default:
                    console.log(error);
            }
        }
    }
    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormFields({...formFields, [name]: value})
    };

    return (
        <div className='sign-up-container'>
            <h2>Already Have an Account</h2>
            <span>Sign in with your email and password</span>
            <form onSubmit={handleSubmit}>
                <FormInput label="Email" type="email" required onChange={handleChange} name='email' value={email} />

                <FormInput label="Password" type="password" required onChange={handleChange} name='password' value={password} />
                <div className='buttons-container'>
                    <Button buttonType={BUTTON_TYPE_CLASSES.base} type="submit">Sign In</Button>
                    <Button buttonType={BUTTON_TYPE_CLASSES.google} type='button' onClick={signInWithGoogle} >Sign In with Google</Button>
                </div>
            </form>
        </div>
    )
}

export default SignInForm