import { useContext } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { UserContext } from '../../contexts/user.content';
import { signOutUser } from '../../utils/firebase/firebase.utils';
import './navigation.component.css';

const Navigation = () => {
  const { currentUser, setCurrentUser } = useContext(UserContext)

  const signOutHandler = async () => {
    //console.log(currentUser)
    await signOutUser();
  }

  return (
    <>
      <nav className='navigator-container'>
        <ul>
          <Link className="navigator" to="/home">Home</Link>
          <Link className='navigator' to="/about">About</Link>
          <Link className='navigator' to="/contact">Contact</Link>
          { currentUser ? 
          <span className='navigator' onClick={signOutHandler}>Sign Out</span> : 
          <Link className='navigator' to='/auth'>Sign In</Link> }
        </ul>
      </nav>
      <div className='nav-outlet'>
        <Outlet />
      </div>
      <footer>
        <div className="footer-container">
          <div className="contact">
            <h3>Contact Us</h3>
            <p>Email: devflowpro@gmail.com</p>
            <p>Phone: (630) 251-9140</p>
            <p>Address: 1550 bay St, San Fransisco USA</p>
          </div>
          <div className="links">
            <h3>Quick Links</h3>
            <ul>
              <li><a href="/about">About Us</a></li>
              <li><a href="/about">Pricing</a></li>
              <li><a href="/about">Features</a></li>
            </ul>
          </div>
          <div className="social">
            <h3>Connect with Us</h3>
            <ul>
              <li><a href="https://www.facebook.com/devflowpro">facebook<i className="fab fa-facebook"></i></a></li>
              <li><a href="https://twitter.com/devflowpro">Twitter<i className="fab fa-twitter"></i></a></li>
              <li><a href="https://www.instagram.com/devflowpro/">Insta<i className="fab fa-instagram"></i></a></li>
              <li><a href="https://www.linkedin.com/company/devflowpro/">LinkedIn<i className="fab fa-linkedin"></i></a></li>
            </ul>
          </div>
        </div>
        <div className="disclaimer">
          <p>&copy; 2023 DevFlowPro. All rights reserved. This website is not affiliated with or endorsed by any official website or organization.</p>
        </div>
      </footer>
    </>
  );
}

export default Navigation;