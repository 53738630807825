import React, { useState, useEffect } from 'react';
import './App.css';
import { Route, Routes } from "react-router-dom";
import Navigation from './routes/navigation/navigation.component';
import Home from './routes/home/home.component';
import About from './routes/about/about.component';
import Contact from './routes/contact/contact.component';
import QuickStart from './routes/quick_start/quick_start.component';
import Placeholder from './routes/placeholder/placeholder.component';
import Preview from './routes/preview/preview.component';
import Authentication from './routes/authentication/authentication.component';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./routes/paymentForm/paymentForm.component";
import { stripePromise } from './utils/stripe/stripe.utils';

const App = () => {
  return(
    <Routes>
      <Route path='/payment' element={<PaymentForm/>}/>
      <Route path="/" element={<Navigation />} >
        <Route index element={<Home />} />
        <Route path='home/' element={<Home />} />
        <Route path='about/' element={<About />} />
        <Route path='contact/' element={<Contact />} />
        <Route path='auth/' element={<Authentication />} />
        <Route path='quick_start/' element={<QuickStart />} />
      </Route>
    </Routes>
  );
};

export default App;