import { useState, useContext } from "react";
import { createAuthUserWithEmailAndPassword, createUserDocumentFromAuth } from "../../utils/firebase/firebase.utils";
import FormInput from "../form-input/form-input.component";
import Button from "../button/button.component";
import "./sign-up-form.styles.css";
import { auth } from "../../utils/firebase/firebase.utils";
import { UserContext } from "../../contexts/user.content";

const defaultFormFields = {
    displayName: '',
    email: '',
    password: '',
    confirmPassword: '',
};

const SignUpForm = () => {
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { displayName, email, password, confirmPassword } = formFields;

    const { setCurrentUser } = useContext(UserContext);

    //console.log(formFields);
    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log(event.target.displayName.value);

        if(password !== confirmPassword) {
            alert("passwords do not match");
            return
        } 

        try {
            console.log("display name", displayName);
            const {user} = await createAuthUserWithEmailAndPassword(email, password);
            console.log(auth);

            console.log("display name 2", displayName);
            await createUserDocumentFromAuth(user, { displayName });
            resetFormFields();

        } catch(error) {
            if(error.code === 'auth/email-already-in-use') {
                alert("email already in use");
            }
            console.log('user created encountered an error', error);
        }
        
    }
    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormFields({...formFields, [name]: value})
    };

    return (
        <div className='sign-up-container'>
            <h2>Dont Have an Account?</h2>
            <span>Sign up with your email and password</span>
            <form onSubmit={handleSubmit}>
                <FormInput label="Display Name" type="text" required onChange={handleChange} name='displayName' value={displayName} />

                <FormInput label="Email" type="email" required onChange={handleChange} name='email' value={email} />

                <FormInput label="Password" type="password" required onChange={handleChange} name='password' value={password} />

                <FormInput label="Confirm Password" type="password" required onChange={handleChange} name='confirmPassword' value={confirmPassword} />

                <Button type="submit">Sign Up</Button>
            </form>
        </div>
    )
}

export default SignUpForm