import { initializeApp } from "firebase/app";
import { 
  getAuth, 
  signInWithRedirect, 
  signInWithPopup, 
  GoogleAuthProvider, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut,
  onAuthStateChanged
} from 'firebase/auth'; 
import { getFirestore, doc, getDoc, setDoc, collection, writeBatch, query, getDocs } from 'firebase/firestore';

// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCKn3foeeoKSy4EJGesCGk8LHXBGfdoKfw",
  authDomain: "dev-flow-16ec8.firebaseapp.com",
  projectId: "dev-flow-16ec8",
  storageBucket: "dev-flow-16ec8.appspot.com",
  messagingSenderId: "62917384305",
  appId: "1:62917384305:web:eb0509403de2e92abca3d0",
  measurementId: "G-1M9Z21BZBJ"
};


/* firebaseConfig = {
    apiKey: "AIzaSyClWISHujFA-5va2n0KFNUfnJgy3WxTbUQ",
    authDomain: "crwn-clothing-db-57e80.firebaseapp.com",
    projectId: "crwn-clothing-db-57e80",
    storageBucket: "crwn-clothing-db-57e80.appspot.com",
    messagingSenderId: "255346011094",
    appId: "1:255346011094:web:5c40f357a6e1ac94b6d277"
  }; */
  
  // Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig);
  const analytics = getAnalytics(firebaseApp);
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: "select_account"
  });

  export const auth = getAuth();
  export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
  export const signInWithGoogleRedirect = () => signInWithRedirect(auth, provider);
  export const db = getFirestore();
  
  export const addCollectionAndDocuments = async (collectionKey, objectsToAdd) => {
    const collectionRef = collection(db, collectionKey);
    const batch = writeBatch(db);

    objectsToAdd.forEach((object) => {
      const docRef = doc(collectionRef, object.title.toLowerCase());
      batch.set(docRef, object);
    })

    await batch.commit();
    console.log("done")
  }

  export const getCategoriesAndDocuments = async () => {
    const collectionRef = collection(db, 'categories');
    const q = query(collectionRef);

    const querySnapshot = await getDocs(q);
    const categoryMap = querySnapshot.docs.reduce((acc, docSnapshot) => {
      const {title, items} = docSnapshot.data();
      acc[title.toLowerCase()] = items;
      return acc;
    }, {});

    return categoryMap;
  }
  export const createUserDocumentFromAuth = async (userAuth, additionalInformation = {}) => {
    if(!userAuth) return;

    console.log("db");
    console.log(db);
    console.log("userAuth.uid");
    console.log(userAuth.uid);
    const userDocRef = doc(db, 'users', userAuth.uid);
    console.log("userDocRef");
    console.log(userDocRef);

    console.log("calling getDoc()")
    const userSnapshot = null //await getDoc(userDocRef);
    console.log("userSnapshot");
    console.log(userSnapshot);
    //console.log(userSnapshot.exists());

    if(true) { //!userSnapshot.exists()) {
        const { displayName, email } = userAuth;
        const createdAt = new Date();
        
        try {
            console.log('setting doc',additionalInformation['displayName'], email);
            await setDoc(userDocRef, {
                displayName,
                email,
                createdAt,
                ...additionalInformation,
            });
        } catch (error) {
            console.log('error creating the user', error.message);
        }
    }

    return userDocRef;
    // if user data does not exist
    // create / set the document with the data from user Auth in my collection
    // if user data exists
  }

  export const createAuthUserWithEmailAndPassword = async (email, password) => {
    if(!email || !password) return;

    //console.log("AUTH", auth);
    return await createUserWithEmailAndPassword(auth, email, password);

  }

  export const signInAuthUserWithEmailAndPassword = async (email, password) => {
    if(!email || !password) return;
    console.log(email);
    console.log(password);
    return await signInWithEmailAndPassword(auth, email, password);
  }

  export const signOutUser = async () => signOut(auth);

  export const onAuthStateChangedListener = (callback) => onAuthStateChanged(auth, callback);
  